import dayjs from 'dayjs'
import { attrRankController } from '~/apis/back/services'
import type { RankListAttrDto } from '~/apis/back/types/RankListAttrDto'
import { ENUM_EventType, ENUM_PageName, E_RANK_TYPE } from '~/enums'
import type { SelectItemClickEvent, ViewItemListClickEvent } from '~/types/event-tracking'

interface PropsData {
  itemIndex?: number
  itemData: RankListAttrDto
  rankType?: string
}

export function useAttractionList() {
  const { locale } = useLocale()
  const { doSearch, confirmAttrList } = useSearchAttr()

  const { filterConditions, resetDetailFilter, handleListParams, getRequestFilterConditions } = useFilterConditionsStore()
  const route = useRoute()
  const userStore = useUserStore()
  const gtm = useGtm()
  const isMobile = useMobileByScreen()
  const userInfoStore = useUserStore()
  const filterCookie = useCookie('azgo-filter-conditions')

  const data = useState('azgo_attraction_list', () => ({
    list: {
      items: [] as RankListAttrDto[],
      loading: true,
      error: false,
      showErrorBtn: true,
    },
  }))

  function trackViewDealEvent(props: PropsData, isRankList = false) {
    const selectItemParams: SelectItemClickEvent = {
      event: ENUM_EventType.SelectItem,
      page_category: ENUM_PageName.List,
      prd_type: route.query.tab_key?.toString() === '1' ? 'Attractions' : 'All',
      city_id: filterConditions.selectedCity?.destinationId,
      city: filterConditions.selectedCity?.destinationName,
      country_code: filterConditions.selectedCity?.countryCode,
      locale: locale.value,
      search_date: dayjs(filterConditions.date).format('YYYY-MM-DD'),
      campaign_id: route.query.campaign_id as string ?? '',
      campaign_name: route.query.campaign_name as string ?? '',
      currency: userStore.userInfo.currency,
      attraction_id: props.itemData.attrId?.toString() || '',
      attraction_name: props.itemData.attrName!,
      attraction_list_position: isRankList ? props.itemIndex || props.itemIndex === 0 ? props.itemIndex + 1 : -1 : undefined,
      attraction_price: props.itemData.lowPrice!,
    }

    gtm?.trackEvent(selectItemParams)
  }

  function trackViewItemListEvent() {
    const params: ViewItemListClickEvent = {
      event: ENUM_EventType.ViewItemList,
      page_category: ENUM_PageName.List,
      locale: locale.value,
      city_id: filterConditions.selectedCity?.destinationId,
      city: filterConditions.selectedCity?.destinationName,
      country_code: filterConditions.selectedCity?.countryCode,
      campaign_id: route.query.campaign_id as string ?? '',
      campaign_name: route.query.campaign_name as string ?? '',
      search_date: dayjs(filterConditions.date).format('YYYY-MM-DD'),
      attraction_number: data.value?.list?.items?.length ?? 0,
      prd_type: 'Attractions',
    }
    gtm?.trackEvent(params)
  }

  function navigatorToAttractionDetail(props: PropsData, isRankList = false) {
    resetDetailFilter()
    filterCookie.value = JSON.stringify(filterConditions)
    const routeOpts = {
      path: `/attractions/detail/${makeDetailRoute(props.itemData.enTitle || '')}`,
      query: {
        ...route.query,
        attrId: props.itemData.attrId,
        // 清除列表页搜索条件
        tab_key: undefined,
        query: undefined,
      },
    }
    trackViewDealEvent(props, isRankList)
    setTimeout(() => {
      return navigateTo(routeOpts, {
        open: isMobile.value
          ? undefined
          : {
              target: '_blank',
            },
      })
    }, 10)
  }

  async function fetchSearchAttraction() {
    if (route.query.query) {
      const result = await doSearch(route.query.query as string)
      confirmAttrList.value = {
        [E_RANK_TYPE.EXPERIENCE]: [],
        [E_RANK_TYPE.ATTRACTIONS]: result,
      }
    }
  }

  async function fetchRankList() {
    const paramsRes = handleListParams()
    data.value.list.loading = true
    data.value.list.error = false
    data.value.list.showErrorBtn = true

    const params = {
      ...getRequestFilterConditions(),
      currency: userInfoStore.userInfo.currency,
      rankType: E_RANK_TYPE.ATTRACTIONS,
      ...paramsRes,
      tagIdList: undefined,
      destinationId: Number(route.query?.destinationId),
    }
    const { data: res } = await attrRankController.rankListPost(params)
    if (res.value?.errorCode === '200') {
      if (res.value?.data?.rankListAttrList?.length) {
        data.value.list.items = res.value.data?.rankListAttrList
        trackViewItemListEvent()
      }
      else {
        data.value.list.error = true
        data.value.list.showErrorBtn = false
      }
    }
    else {
      data.value.list.error = true
    }

    // Simulate loading
    setTimeout(() => {
      data.value.list.loading = false
    }, 200)
  }

  return {
    data,
    fetchRankList,
    fetchSearchAttraction,
    navigatorToAttractionDetail,
  }
}
